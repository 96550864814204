import { setToken } from '@/common/util';
import weixinAuthorize, { hasCodeAndState, snsapiUserInfo } from '@/features/weixin/authorize';
import { authorize } from './weixin/config'
import wxapi from "@/common/wxApi.js";
import store from '@/store'
import Vue from 'vue'
import { tokenStorage } from '@/features/weixin/token';

// 非静默授权获取微信用户信息
export const weixinAuthorizeUserInfo = () => snsapiUserInfo({ state: 1 });

const checkLogin = async (isForceWeixinAuthorize = false) => {
  // 登录过期后，清除缓存token，强制授权登录
  if (isForceWeixinAuthorize) setToken("")

  let access_token = tokenStorage();

  // 开发者便利代码 仅开发环境使用
 /*  if (process.env.NODE_ENV === "development") {
    if (!sessionStorage.getItem('userInfo')) sessionStorage.setItem('userInfo', '{"id":81,"uid":57954,"mobile":"17319110093","type":1,"nickname":"吕小布","realname":"","head_portrait":"https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTLx7wqseNibeE9lFIzfhxpSNeKdg6PHqrDWHpxdiay8eU99Wnfbtc3bEFC3OsYziaRic0lUicrjQQI7qiag/132","gender":1,"status":1,"is_agent":1,"is_dst":1,"is_worker":1,"is_debug":1,"device":[],"is_student":0}')
    access_token = sessionStorage.getItem('auth')
    if (!access_token) {
      const access_token = prompt("检测到当前为开发环境 请先输入 access_token");
      sessionStorage.setItem('auth', JSON.stringify({
        access_token,
        expires_in: ~~(Date.now() / 1000) + 60 * 60 * 24 
      }))
      setToken(access_token)
    }
  } */

  if (!access_token || isForceWeixinAuthorize || hasCodeAndState) {
    // 这里是因为多个公众号的问题，动态获取appid
    let scope;
    if (!hasCodeAndState) {
      await getOauthUrl();
    }

    // 微信授权逻辑
    let res = await weixinAuthorize()

    // 是否是静默授权获取微信用户信息和token
    scope = res.state == 1 ? 'snsapi_userinfo' : 'snsapi_base'

    res = await getAuUserInfo(res.code, scope, res.state);
    setToken(res.token)
    access_token = res.token
  }

  // 登录成功后上传日志
  await upLog() 

  return access_token
}

export default checkLogin;

let vm = new Vue()

// 获取授权code的url连接
const getOauthUrl = async () => {
  let res = await vm.$api.common.appid()
  authorize.appid = res.data
  sessionStorage.setItem('appid', res.data)
  return res;
};

// 根据授权code获取 是否是静默或者非静默登录 state: 0 是,  1 否  改版
const getAuUserInfo = async (code, scope, state) => {
  let res = await vm.$api.common.wx({
    'code': code, 'sns_scope': scope, 'silent': state
  })
  // alert('获取是否是新用户'+res.info.is_new+'silent='+state+'sns_scope='+scope)
  if (res.token && res.info) {
    let url = sessionStorage.getItem("yurl")
    if (!url || url.split("#")[1].indexOf('/login') >= 0) {
      url = '/#/home'
      sessionStorage.setItem("yurl", url)
    }
    store.commit('setUserInfo', res.info)
    let expires_in = Math.floor(new Date().getTime() / 1000) + Number(res.expiration_time)
    let auth = {
      'expires_in': expires_in,
      'access_token': res.token
    }
    sessionStorage.setItem("auth", JSON.stringify(auth))
  }
  return res;
};

// 登录日记
const upLog = async () => {
  wxapi.wxRegister(wxapi.getLocation, "getLocation");
};